  /*? New NavBar  */

  .sidebar-main {
    height: 99vh;
    margin: 4px;
    display: none;
  }

  .header-nav-sp {
    /* position: fixed;
    top: 0;
    right: 0;
     */
    margin-top: 5px;
    position: sticky;
    top: 0;
    z-index: 1000;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 2%;
    transition: all .50s ease;
    -webkit-transition: all .50s ease;
    -moz-transition: all .50s ease;
    -ms-transition: all .50s ease;
    -o-transition: all .50s ease;
    border: var(--new-frame) solid 1px;
    background-color: var(--bg);
  }

  .header-nav-sp>.logo {
    display: flex;
    align-items: center;
  }


  /* *Pages Sections   */
  /* ?Navigation Top Bar  */

  /* NavBar Top */

  .navbar {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    padding: 0 20px;
    background: var(--blanco);
    color: #19191c;
  }

  .navbar-logo {
    display: flex;
    align-items: center;
    gap: 1px;
    /* width: 160px; */

  }

  .hrline {

    border: none;
    border-left: 1px solid hsla(200, 10%, 50%, 100);
    height: 95%;
    width: 10px;
  }


  .navbar-logo-icon {
    height: 48px;
  }

  .navbar-logo-post {
    height: 35px;
  }



  .navbar-logo {
    display: flex;
    align-items: center;
  }

  .navbar-logo-text {
    color: #000;
    font-weight: 700;
    font-family: var(--fuente-main);
    display: block;
    font-size: 1.2rem;
  }


  /* Links Menu top  */
  .nabvar-sp {
    display: flex;
  }


  .nabvar-sp span {
    display: flex;
    align-items: center;
    font-size: 18px;
    margin: 0px 0px;
    cursor: pointer;
    text-decoration: none;
    transition: all .50s ease;
    -webkit-transition: all .50s ease;
    -moz-transition: all .50s ease;
    -ms-transition: all .50s ease;
    -o-transition: all .50s ease;
  }

  .nav-link {
    display: block;
    padding: .5rem .7rem;
    color: #909090 !important;
    text-decoration: none;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
    -webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
    -moz-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
    -ms-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
    -o-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
  }

  .nav-pills .nav-link.active,
  .nav-pills .show>.nav-link {
    color: #ffffff !important;
    background-color: #000 !important;
  }

  .nav-underline .nav-link.active,
  .nav-underline .show>.nav-link {
    font-weight: 700;
    color: #000 !important;
    ;
    border-bottom-color: currentcolor;
  }

  /* Links  */

  .nabvar-sp a {
    font-weight: normal;
    text-decoration: none;
  }

  .nabvar-sp a:hover {
    cursor: pointer;

  }

  .no-active-link {
    color: rgb(108, 108, 108);
    font-weight: 500;
  }

  .active-link {
    color: var(--primario);
    font-weight: 800;
  }

  .close-login {
    color: #000;
    cursor: pointer;
    transition: all ease-in-out .2s;
  }

  .close-login:hover {
    color: red;
    cursor: pointer;
    border: 1px solid red;
    background-color: rgba(255, 70, 70, 0.199);

  }

  .conrainer-profile-img-sidebar {
    position: relative;
    width: 45px;
    height: 45px;
    background-color: #ffffff;

  }

  .profile-img-sidebar {
    width: 100%;
    height: 45px;
    object-fit: cover;
  }

  .sp-grid {
    display: grid;
    gap: 2;
    grid-template-columns: 200px 1fr;
  }

  .fixed-column {
    position: fixed;
    width: 200px;
  }

  .other-column {
    margin-left: 200px;
  }


  .list-navigation-menu {
    display: inline;

  }

  .icon-menu-sp {
    display: none;
  }

  .border-menu-start {
    border: .5px solid #dee2e6;
  }


  .area-profile {
    border: .5px solid #dee2e6;
  }

  .text-subtitle-menu {

    display: none;
  }

  @media (max-width: 1190px) {
    .nabvar-sp {
      position: absolute;
      top: 80%;
      right: 2%;
      width: 270px;
      height: auto;
      background-color: var(--blanco);

      flex-direction: column;
      justify-content: flex-start;
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
      transition: all .50s ease;
      -webkit-transition: all .50s ease;
      -moz-transition: all .50s ease;
      -ms-transition: all .50s ease;
      -o-transition: all .50s ease;
    }

  }


  @media (max-width: 912px) {

    .icon-menu-sp {
      padding: 4px 6px;
    }

    .sp-grid {
      display: flex;
      flex-direction: column;
      /* grid-template-columns: 1fr; */
    }

    .fixed-column {
      position: fixed;
      z-index: 199;
      width: 100%;
      top: 0;
    }

    .other-column {
      margin-top: 60px;
      display: block;
    }

    .sidebar-main {
      height: 100%;
      margin: 4px;
      display: none;
    }

    .icon-menu-sp {
      display: block;
    }

    .navbar-logo-text {
      display: none;
    }

    .list-menu-main-sp {
      display: none;
    }

    .list-menu-main-sp-open {
      display: inline;
    }

    .border-menu-start {
      border: 0px solid #dee2e6;
    }

    .border-menu-top {
      border: .5px solid #dee2e6;
    }
  }

  @media (max-width: 768px) {

    .navbar-logo-icon {
      height: 50px;
    }

    .nabvar-sp {
      position: absolute;
      top: 80%;
      right: 2%;
      width: 270px;
      height: auto;
      background-color: var(--blanco);
      flex-direction: column;
      justify-content: flex-start;
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
      transition: all .50s ease;
      -webkit-transition: all .50s ease;
      -moz-transition: all .50s ease;
      -ms-transition: all .50s ease;
      -o-transition: all .50s ease;
      border: #c3c3c3 solid 1.5px;
    }

    .icon-menu-sp {
      padding: 4px 6px;
    }

    .sp-grid {
      display: flex;
      flex-direction: column;
      /* grid-template-columns: 1fr; */
    }

    .fixed-column {
      position: fixed;
      z-index: 199;
      width: 100%;
      top: 0;
    }

    .other-column {
      margin-top: 60px;
      display: block;
    }

    .sidebar-main {
      height: 100%;
      margin: 4px;
      display: none;
      border: .5px solid #dee2e6;

    }

    .icon-menu-sp {
      display: block;
    }

    .navbar-logo-text {
      display: none;
    }

    .sidebar-main-open {
      height: 100%;
      margin: 4px;
      display: inline;

      border: .5px solid #dee2e6;
    }

    .list-menu-main-sp {
      display: none;

    }

    .list-menu-main-sp-open {
      display: block;

    }


    .area-profile {
      border: .5px solid #ffffff;
    }


    .border-menu-start {
      border: 0px solid #dee2e6;
    }

    .border-menu-top {
      border: .5px solid #dee2e6;
    }

    .text-subtitle-menu {

      display: inline;
    }

    .slide-in-blurred-top {
      animation: slide-in-blurred-top .6s cubic-bezier(.23, 1.000, .32, 1.000) both
    }




    @keyframes slide-in-blurred-top {
      0% {
        transform: translateY(-1000px) scaleY(2.5) scaleX(.2);
        transform-origin: 50% 0;
        filter: blur(40px);
        opacity: 0
      }

      100% {
        transform: translateY(0) scaleY(1) scaleX(1);
        transform-origin: 50% 50%;
        filter: blur(0);
        opacity: 1
      }
    }

  }