.main-login-sp {
    display: flex;
    justify-content: center;
    align-items: center;
    /* min-height: 100vh; */

    margin: 0;
}

.img-logo-brand {
    width: 80px;
    margin: 10px 0px;

}

.title-main-login {
    font-size: 25px;
    font-weight: 700;
}





.form-signin-sp {
    height: 100%;
    width: 450px;
    padding: 1px 20px;
    /* border: 1.5px solid #efefef; */

    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;

    transition: all .50s ease;
    -webkit-transition: all .50s ease;
    -moz-transition: all .50s ease;
    -ms-transition: all .50s ease;
    -o-transition: all .50s ease;


}

.login-wrapper {
    border: 1px solid #efefef;
    height: 180px;
    width: 100%;
    overflow-y: scroll;
    /* margin: 5px 0; */
    border-radius: 2px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
 
}

.password {
    position: relative;
}

.toggle {
    position: absolute;
    top: 50%;
    right: 16px;
    translate: 0 -50%;
    width: 30px;
    height: 30px;
    /* background-image: url("show.svg"); */
    background-size: 85%;
    background-position: center;
    background-repeat: no-repeat;
}


/* Button send  */
button.control {
    margin-top: 10px;
    cursor: pointer;
    width: 100%;
    height: 46px;
    padding: 0 16px;
    background: var(--primario);
    color: #ffffff;
    border: 0;
    font-family: inherit;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    letter-spacing: 2px;
    transition: all 0.375s;
}

button.control:hover {
    background: var(--primario-dark);
    color: #ffffff;
}

.info-display {


    height: 630px;


}

.bg-login {
    width: 100%;
    height: 100%;
    z-index: -1998;
    /* border-image: fill 0 linear-gradient(#0003, #000); */
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    position: absolute;
   
}


.img-display {

    display: none;
}

.info-bussiness {
    width: 500px;
    z-index: 10;
    position: absolute;
    left: 5%;
    top: 24%;
    background-color: #07070728;
    backdrop-filter: blur(5px);
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    color: #0d6efd;
    border: 1px solid #e7e7e747;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.gradient-background {
  height: 100vh;
  background: linear-gradient(45deg, #ff6b6b, #f4d03f, #38ada9, #0abde3);
  background-size: 300% 300%;
  animation: gradient-animation 15s ease infinite;
}

/* End Button End  */

@media (max-width: 992px) {

    .main-login-sp {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 100vh;
        margin: 0;    }

            .form-signin-sp {
        width: 60%;
        padding: 10px 15px;
        border: 1px solid #ebebebf4;

    }



    .info-display {
        display: none;
    }
}

@media (max-width: 767px) {

    .img-logo-brand {
        width: 80px;

    }

    .title-main-login {
        font-size: 22px;
    }

    .form-signin-sp {
        width: 100%;
        padding: 10px 15px;
        border: 1px solid #ebebebf4;

    }

    .info-display {
        display: none;
    }

    .img-display {

        display: inline;

    }
}